import React, { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { HASURA_HOST_PATH } from './index';

const QUERY_FILE_PATH = 'https://storage.googleapis.com/graphql-engine-cdn.hasura.io/conf/graphql-summit/queries.graphql';

const USER_MUTATION = gql`
  mutation insertUser($name: String!, $restaurantId: String!, $twitter: String, $email: String) {
    insert_users(objects: {
      name: $name,
      restaurant_id: $restaurantId,
      twitter_handle: $twitter,
      email: $email
    }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const SUBSCRIPTION_QUERY = gql`
  subscription {
    verify_remote_relationship(where: {
      name: {_eq: "restaurant"}, 
      table_name: {_eq: "users"},
      table_schema: {_eq: "public"},
      remote_schema: {_eq: "yelp"}
    }) {
      name
    }
  }
`;

const USER_FINISHED_MUTATION = gql`
  mutation update_users($userId: Int!){
    update_users(_set: {updated_at: "now()"} where: {id: {_eq: $userId}}) {
      affected_rows
      returning {
        created_at
        updated_at
      }
    }
  }
`;

const PRE_VERIFY_QUERY = gql`
  query preVerifyQuery {
    verify_remote_relationship(where: {
      name: {_eq: "restaurant"}, 
      table_name: {_eq: "users"},
      table_schema: {_eq: "public"},
      remote_schema: {_eq: "yelp"}
    }) {
      name
    }
  }
`;

const UserMutation = (props) => {
  const client = useApolloClient();
  const [userName, setUserName] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [email, setEmail] = useState('');
  const [isConnecting, setIsConnecting] = useState(false);
  const [verificationDone, setVerificationDone] = useState(false);
  const { restaurant } = props;

  const insertUser = (client, restaurantId, userName, twitterHandle, email) => {
    setIsConnecting(true);
    client.query({query: PRE_VERIFY_QUERY, fetchPolicy: 'network-only'}).then((data) => {

      if(data.data && data.data.verify_remote_relationship && data.data.verify_remote_relationship.length) {
        setIsConnecting(false);
        alert('Relationship already exists');
      } else {
        client.mutate({
          mutation: USER_MUTATION,
          variables: {
            name: userName,
            restaurantId: restaurantId,
            twitter: twitterHandle,
            email: email
          }
        }).then((data) => {
          if(data.data && data.data.insert_users && data.data.insert_users.affected_rows) {

            const userId = data.data.insert_users.returning[0].id;

            client.subscribe({
              query: SUBSCRIPTION_QUERY
            }).subscribe({
              next(data) {
                if(data && data.data && data.data.verify_remote_relationship.length) {
                  // update state
                  setIsConnecting(false);
                  setVerificationDone(true);

                  // now update user updated_at
                  client.mutate({
                    mutation: USER_FINISHED_MUTATION,
                    variables: {
                      userId: userId
                    }
                  })
                }
              },
              error(err) { console.error('err', err); },
            });

            // take user to console
            const protocol = window.location.protocol === 'https:' ? 'https://' : 'http://'
            const url = protocol + HASURA_HOST_PATH + '/console/data/schema/public/tables/users/relationships';
            window.open(url, '_blank');
          } else {
            alert('Error. Please try again');
          }

        }).catch((err) => {
          alert('Error. Please try again');
        });
      }
    }).catch((err) => {
      alert('Error. Please try again');
    });
  };

  let btnClass = 'btn btn-info';
  let btnText = 'Connect';
  let disabledState = false;
  if(isConnecting) {
    btnClass = 'btn btn-warning';
    btnText = (<span> Waiting for remote relationship ... <i className='fa fa-spinner fa-spin' /></span>);
    disabledState = true;
  } else if(verificationDone && !isConnecting) {
    btnClass = 'btn btn-success';
    btnText = 'Connected :)'
    disabledState = true;
  }

  const protocol = window.location.protocol === 'https:' ? 'https://' : 'http://'
  const queryUrl = protocol + HASURA_HOST_PATH + '/console?query_file=' + QUERY_FILE_PATH;

  return (
    <div>
      {restaurant && restaurant.value ? (
        <div>
          <p>Enter your username</p>
          <input className="nameInput" type="text" onChange={(e) => setUserName(e.target.value)} value={userName} />
          <p>Enter your twitter handle (optional)</p>
          <input className="nameInput" type="text" onChange={(e) => setTwitterHandle(e.target.value)} value={twitterHandle} />
          <p>Enter your email (optional)</p>
          <input className="nameInput" type="text" onChange={(e) => setEmail(e.target.value)} value={email} />
          <p>Establish connection</p>
          <p className="helpText">Create relationship "restaurant" from users.restaurant_id to business.id</p>
          <button 
            className={btnClass}
            disabled={disabledState}
            type="button"
            onClick={(e) => insertUser(client, restaurant.value, userName, twitterHandle, email)}
          >
            {btnText}
          </button>
          {verificationDone && !isConnecting ?
            <a href={queryUrl}>
              <button
                className={btnClass + ' queryBtn'}
                type="button"
              >
                Let's make a query!
              </button>
            </a>
          : null
          }
        </div>) : null}
    </div>
  );
};

export default UserMutation;