const cities = [
  {
    "value": "buenos aires",
    "label": "Buenos Aires"
  },
  {
    "value": "adelaide",
    "label": "Adelaide"
  },
  {
    "value": "brisbane",
    "label": "Brisbane"
  },
  {
    "value": "melbourne",
    "label": "Melbourne"
  },
  {
    "value": "perth",
    "label": "Perth"
  },
  {
    "value": "sydney",
    "label": "Sydney"
  },
  {
    "value": "wien",
    "label": "Wien"
  },
  {
    "value": "antwerpen",
    "label": "Antwerpen"
  },
  {
    "value": "bruxelles",
    "label": "Bruxelles"
  },
  {
    "value": "rio de janeiro",
    "label": "Rio de Janeiro"
  },
  {
    "value": "são paulo",
    "label": "São Paulo"
  },
  {
    "value": "calgary",
    "label": "Calgary"
  },
  {
    "value": "edmonton",
    "label": "Edmonton"
  },
  {
    "value": "halifax",
    "label": "Halifax"
  },
  {
    "value": "montréal",
    "label": "Montréal"
  },
  {
    "value": "ottawa",
    "label": "Ottawa"
  },
  {
    "value": "toronto",
    "label": "Toronto"
  },
  {
    "value": "vancouver",
    "label": "Vancouver"
  },
  {
    "value": "santiago",
    "label": "Santiago"
  },
  {
    "value": "praha",
    "label": "Praha"
  },
  {
    "value": "københavn",
    "label": "København"
  },
  {
    "value": "helsinki",
    "label": "Helsinki"
  },
  {
    "value": "lyon",
    "label": "Lyon"
  },
  {
    "value": "marseille",
    "label": "Marseille"
  },
  {
    "value": "paris",
    "label": "Paris"
  },
  {
    "value": "berlin",
    "label": "Berlin"
  },
  {
    "value": "frankfurt am main",
    "label": "Frankfurt am Main"
  },
  {
    "value": "hamburg",
    "label": "Hamburg"
  },
  {
    "value": "köln",
    "label": "Köln"
  },
  {
    "value": "münchen",
    "label": "München"
  },
  {
    "value": "香港",
    "label": "香港"
  },
  {
    "value": "milano",
    "label": "Milano"
  },
  {
    "value": "roma",
    "label": "Roma"
  },
  {
    "value": "大阪市",
    "label": "大阪市"
  },
  {
    "value": "東京",
    "label": "東京"
  },
  {
    "value": "kuala lumpur",
    "label": "Kuala Lumpur"
  },
  {
    "value": "méxico, d.f.",
    "label": "México, D.F."
  },
  {
    "value": "auckland",
    "label": "Auckland"
  },
  {
    "value": "oslo",
    "label": "Oslo"
  },
  {
    "value": "manila",
    "label": "Manila"
  },
  {
    "value": "kraków",
    "label": "Kraków"
  },
  {
    "value": "warszawa",
    "label": "Warszawa"
  },
  {
    "value": "lisboa",
    "label": "Lisboa"
  },
  {
    "value": "dublin",
    "label": "Dublin"
  },
  {
    "value": "singapore",
    "label": "Singapore"
  },
  {
    "value": "barcelona",
    "label": "Barcelona"
  },
  {
    "value": "madrid",
    "label": "Madrid"
  },
  {
    "value": "stockholm",
    "label": "Stockholm"
  },
  {
    "value": "zürich",
    "label": "Zürich"
  },
  {
    "value": "台北市",
    "label": "台北市"
  },
  {
    "value": "amsterdam",
    "label": "Amsterdam"
  },
  {
    "value": "i̇stanbul",
    "label": "İstanbul"
  },
  {
    "value": "belfast",
    "label": "Belfast"
  },
  {
    "value": "brighton",
    "label": "Brighton"
  },
  {
    "value": "bristol",
    "label": "Bristol"
  },
  {
    "value": "cardiff",
    "label": "Cardiff"
  },
  {
    "value": "edinburgh",
    "label": "Edinburgh"
  },
  {
    "value": "glasgow",
    "label": "Glasgow"
  },
  {
    "value": "leeds",
    "label": "Leeds"
  },
  {
    "value": "liverpool",
    "label": "Liverpool"
  },
  {
    "value": "london",
    "label": "London"
  },
  {
    "value": "manchester",
    "label": "Manchester"
  },
  {
    "value": "phoenix",
    "label": "Phoenix"
  },
  {
    "value": "scottsdale",
    "label": "Scottsdale"
  },
  {
    "value": "tempe",
    "label": "Tempe"
  },
  {
    "value": "tucson",
    "label": "Tucson"
  },
  {
    "value": "alameda",
    "label": "Alameda"
  },
  {
    "value": "albany",
    "label": "Albany"
  },
  {
    "value": "alhambra",
    "label": "Alhambra"
  },
  {
    "value": "anaheim",
    "label": "Anaheim"
  },
  {
    "value": "belmont",
    "label": "Belmont"
  },
  {
    "value": "berkeley",
    "label": "Berkeley"
  },
  {
    "value": "beverly hills",
    "label": "Beverly Hills"
  },
  {
    "value": "big sur",
    "label": "Big Sur"
  },
  {
    "value": "burbank",
    "label": "Burbank"
  },
  {
    "value": "concord",
    "label": "Concord"
  },
  {
    "value": "costa mesa",
    "label": "Costa Mesa"
  },
  {
    "value": "culver city",
    "label": "Culver City"
  },
  {
    "value": "cupertino",
    "label": "Cupertino"
  },
  {
    "value": "daly city",
    "label": "Daly City"
  },
  {
    "value": "davis",
    "label": "Davis"
  },
  {
    "value": "dublin",
    "label": "Dublin"
  },
  {
    "value": "emeryville",
    "label": "Emeryville"
  },
  {
    "value": "foster city",
    "label": "Foster City"
  },
  {
    "value": "fremont",
    "label": "Fremont"
  },
  {
    "value": "glendale",
    "label": "Glendale"
  },
  {
    "value": "hayward",
    "label": "Hayward"
  },
  {
    "value": "healdsburg",
    "label": "Healdsburg"
  },
  {
    "value": "huntington beach",
    "label": "Huntington Beach"
  },
  {
    "value": "irvine",
    "label": "Irvine"
  },
  {
    "value": "la jolla",
    "label": "La Jolla"
  },
  {
    "value": "livermore",
    "label": "Livermore"
  },
  {
    "value": "long beach",
    "label": "Long Beach"
  },
  {
    "value": "los altos",
    "label": "Los Altos"
  },
  {
    "value": "los angeles",
    "label": "Los Angeles"
  },
  {
    "value": "los gatos",
    "label": "Los Gatos"
  },
  {
    "value": "marina del rey",
    "label": "Marina del Rey"
  },
  {
    "value": "menlo park",
    "label": "Menlo Park"
  },
  {
    "value": "mill valley",
    "label": "Mill Valley"
  },
  {
    "value": "millbrae",
    "label": "Millbrae"
  },
  {
    "value": "milpitas",
    "label": "Milpitas"
  },
  {
    "value": "monterey",
    "label": "Monterey"
  },
  {
    "value": "mountain view",
    "label": "Mountain View"
  },
  {
    "value": "napa",
    "label": "Napa"
  },
  {
    "value": "newark",
    "label": "Newark"
  },
  {
    "value": "newport beach",
    "label": "Newport Beach"
  },
  {
    "value": "oakland",
    "label": "Oakland"
  },
  {
    "value": "orange county",
    "label": "Orange County"
  },
  {
    "value": "palo alto",
    "label": "Palo Alto"
  },
  {
    "value": "park la brea",
    "label": "Park La Brea"
  },
  {
    "value": "pasadena",
    "label": "Pasadena"
  },
  {
    "value": "pleasanton",
    "label": "Pleasanton"
  },
  {
    "value": "redondo beach",
    "label": "Redondo Beach"
  },
  {
    "value": "redwood city",
    "label": "Redwood City"
  },
  {
    "value": "sacramento",
    "label": "Sacramento"
  },
  {
    "value": "san bruno",
    "label": "San Bruno"
  },
  {
    "value": "san carlos",
    "label": "San Carlos"
  },
  {
    "value": "san diego",
    "label": "San Diego"
  },
  {
    "value": "san francisco",
    "label": "San Francisco"
  },
  {
    "value": "san jose",
    "label": "San Jose"
  },
  {
    "value": "san leandro",
    "label": "San Leandro"
  },
  {
    "value": "san mateo",
    "label": "San Mateo"
  },
  {
    "value": "san rafael",
    "label": "San Rafael"
  },
  {
    "value": "santa barbara",
    "label": "Santa Barbara"
  },
  {
    "value": "santa clara",
    "label": "Santa Clara"
  },
  {
    "value": "santa cruz",
    "label": "Santa Cruz"
  },
  {
    "value": "santa monica",
    "label": "Santa Monica"
  },
  {
    "value": "santa rosa",
    "label": "Santa Rosa"
  },
  {
    "value": "sausalito",
    "label": "Sausalito"
  },
  {
    "value": "sonoma",
    "label": "Sonoma"
  },
  {
    "value": "south lake tahoe",
    "label": "South Lake Tahoe"
  },
  {
    "value": "stockton",
    "label": "Stockton"
  },
  {
    "value": "studio city",
    "label": "Studio City"
  },
  {
    "value": "sunnyvale",
    "label": "Sunnyvale"
  },
  {
    "value": "torrance",
    "label": "Torrance"
  },
  {
    "value": "union city",
    "label": "Union City"
  },
  {
    "value": "venice",
    "label": "Venice"
  },
  {
    "value": "walnut creek",
    "label": "Walnut Creek"
  },
  {
    "value": "west hollywood",
    "label": "West Hollywood"
  },
  {
    "value": "west los angeles",
    "label": "West Los Angeles"
  },
  {
    "value": "westwood",
    "label": "Westwood"
  },
  {
    "value": "yountville",
    "label": "Yountville"
  },
  {
    "value": "boulder",
    "label": "Boulder"
  },
  {
    "value": "denver",
    "label": "Denver"
  },
  {
    "value": "hartford",
    "label": "Hartford"
  },
  {
    "value": "new haven",
    "label": "New Haven"
  },
  {
    "value": "washington, dc",
    "label": "Washington, DC"
  },
  {
    "value": "fort lauderdale",
    "label": "Fort Lauderdale"
  },
  {
    "value": "gainesville",
    "label": "Gainesville"
  },
  {
    "value": "miami",
    "label": "Miami"
  },
  {
    "value": "miami beach",
    "label": "Miami Beach"
  },
  {
    "value": "orlando",
    "label": "Orlando"
  },
  {
    "value": "tampa",
    "label": "Tampa"
  },
  {
    "value": "atlanta",
    "label": "Atlanta"
  },
  {
    "value": "savannah",
    "label": "Savannah"
  },
  {
    "value": "honolulu",
    "label": "Honolulu"
  },
  {
    "value": "lahaina",
    "label": "Lahaina"
  },
  {
    "value": "iowa city",
    "label": "Iowa City"
  },
  {
    "value": "boise",
    "label": "Boise"
  },
  {
    "value": "chicago",
    "label": "Chicago"
  },
  {
    "value": "evanston",
    "label": "Evanston"
  },
  {
    "value": "naperville",
    "label": "Naperville"
  },
  {
    "value": "schaumburg",
    "label": "Schaumburg"
  },
  {
    "value": "skokie",
    "label": "Skokie"
  },
  {
    "value": "bloomington",
    "label": "Bloomington"
  },
  {
    "value": "indianapolis",
    "label": "Indianapolis"
  },
  {
    "value": "louisville",
    "label": "Louisville"
  },
  {
    "value": "new orleans",
    "label": "New Orleans"
  },
  {
    "value": "allston",
    "label": "Allston"
  },
  {
    "value": "boston",
    "label": "Boston"
  },
  {
    "value": "brighton",
    "label": "Brighton"
  },
  {
    "value": "brookline",
    "label": "Brookline"
  },
  {
    "value": "cambridge",
    "label": "Cambridge"
  },
  {
    "value": "somerville",
    "label": "Somerville"
  },
  {
    "value": "baltimore",
    "label": "Baltimore"
  },
  {
    "value": "ann arbor",
    "label": "Ann Arbor"
  },
  {
    "value": "detroit",
    "label": "Detroit"
  },
  {
    "value": "minneapolis",
    "label": "Minneapolis"
  },
  {
    "value": "saint paul",
    "label": "Saint Paul"
  },
  {
    "value": "kansas city",
    "label": "Kansas City"
  },
  {
    "value": "saint louis",
    "label": "Saint Louis"
  },
  {
    "value": "charlotte",
    "label": "Charlotte"
  },
  {
    "value": "durham",
    "label": "Durham"
  },
  {
    "value": "raleigh",
    "label": "Raleigh"
  },
  {
    "value": "newark",
    "label": "Newark"
  },
  {
    "value": "princeton",
    "label": "Princeton"
  },
  {
    "value": "albuquerque",
    "label": "Albuquerque"
  },
  {
    "value": "santa fe",
    "label": "Santa Fe"
  },
  {
    "value": "las vegas",
    "label": "Las Vegas"
  },
  {
    "value": "reno",
    "label": "Reno"
  },
  {
    "value": "brooklyn",
    "label": "Brooklyn"
  },
  {
    "value": "long island city",
    "label": "Long Island City"
  },
  {
    "value": "new york",
    "label": "New York"
  },
  {
    "value": "flushing",
    "label": "Flushing"
  },
  {
    "value": "cincinnati",
    "label": "Cincinnati"
  },
  {
    "value": "cleveland",
    "label": "Cleveland"
  },
  {
    "value": "columbus",
    "label": "Columbus"
  },
  {
    "value": "portland",
    "label": "Portland"
  },
  {
    "value": "salem",
    "label": "Salem"
  },
  {
    "value": "philadelphia",
    "label": "Philadelphia"
  },
  {
    "value": "pittsburgh",
    "label": "Pittsburgh"
  },
  {
    "value": "providence",
    "label": "Providence"
  },
  {
    "value": "charleston",
    "label": "Charleston"
  },
  {
    "value": "memphis",
    "label": "Memphis"
  },
  {
    "value": "nashville",
    "label": "Nashville"
  },
  {
    "value": "austin",
    "label": "Austin"
  },
  {
    "value": "dallas",
    "label": "Dallas"
  },
  {
    "value": "houston",
    "label": "Houston"
  },
  {
    "value": "san antonio",
    "label": "San Antonio"
  },
  {
    "value": "salt lake city",
    "label": "Salt Lake City"
  },
  {
    "value": "alexandria",
    "label": "Alexandria"
  },
  {
    "value": "arlington",
    "label": "Arlington"
  },
  {
    "value": "richmond",
    "label": "Richmond"
  },
  {
    "value": "burlington",
    "label": "Burlington"
  },
  {
    "value": "bellevue",
    "label": "Bellevue"
  },
  {
    "value": "redmond",
    "label": "Redmond"
  },
  {
    "value": "seattle",
    "label": "Seattle"
  },
  {
    "value": "madison",
    "label": "Madison"
  },
  {
    "value": "milwaukee",
    "label": "Milwaukee"
  }
];

export default cities;