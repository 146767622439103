import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from "react-router-dom";
import ApolloClient from "apollo-client";
import { WebSocketLink } from "apollo-link-ws";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "@apollo/react-hooks";

import App from './App';
import Leaderboard from './Leaderboard';
import * as serviceWorker from './serviceWorker';

const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://'
export const HASURA_HOST_PATH = process.env.REACT_APP_HASURA_HOST_PATH ? process.env.REACT_APP_HASURA_HOST_PATH : 'localhost:8080';

const createApolloClient = authToken => {
  return new ApolloClient({
    link: new WebSocketLink({
      uri: protocol + HASURA_HOST_PATH + "/v1/graphql",
      options: {
        reconnect: true,
        connectionParams: {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      }
    }),
    cache: new InMemoryCache()
  });
};

const client = createApolloClient('');

const mainRoutes = (
	<ApolloProvider client={client}>
	  <Router>
	    <Route
	      path="/"
	      exact
	      render={props => (<App />)}
	    />
	    <Route
	      path="/leaderboard"
	      exact
	      render={props => (<Leaderboard />)}
	    />
	  </Router>
	</ApolloProvider>

);

ReactDOM.render(mainRoutes, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
