import React from 'react';
import AsyncSelect from 'react-select/async';
import options from './Cities';

const LocationInput = ({ selectedLocation, setSelectedLocation }) => {

  const onChange = (value, action) => {
    setSelectedLocation(value);
  };

  const filterLocations = (inputValue) => {
    return options.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(filterLocations(inputValue));
      }, 200);
    });
  const noOptionsMessage = selectedLocation && selectedLocation.length ? "Yelp doesn't support your location" : 'Type your location name';

  return (
    <div>
      <p>Enter your location</p>
      <AsyncSelect 
        cacheOptions 
        loadOptions={promiseOptions} 
        noOptionsMessage={() => noOptionsMessage}
        placeholder="Type your city name" 
        onChange={onChange}
        value={selectedLocation}
      />
    </div>
  );
}

export default LocationInput;
