import React from 'react';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import AsyncSelect from 'react-select/async';
import debounce from "debounce-promise";

const GET_RESTAURANTS = gql`
  query getRestaurants($term: String!, $location: String!) {
    search(term: $term, 
      location: $location,
      limit: 5,
      categories: "restaurants"
    ) {
      business {
        id
        name
        phone
        location {
          city
          country
        }
        categories {
          alias
          title
        }
      }
    }
  }
`;

const RestaurantInput = ({ selectedLocation, restaurant, setRestaurant }) => {

  const client = useApolloClient();

  const onChange = (value, state, props) => {
    setRestaurant(value);
  };

  const promiseOptions = inputValue => {
    return client.query({ 
      query: GET_RESTAURANTS,
      variables: {
        term: inputValue,
        location: selectedLocation.value.toLowerCase()
      }
    })
    .then(data => { 
      if(data.data) {
        const suggestValues = data.data.search.business.map((b) => { return { label: b.name, value: b.id } });
        // setValues(suggestValues);
        return suggestValues;
      }
    })
    .catch(error => console.error(error))
  };

  const wait = 1000; // milliseconds
  const debouncedLoadOptions = debounce(promiseOptions, wait, {
      leading: false
    });

  const noOptionsMessage = restaurant && restaurant.length ? 'Unable to find' : 'Type your restaurant/cuisine';

  return (
    <div>
      {selectedLocation ? (
        <div>
          <p>Enter your Restaurant / Cuisine</p>
          <AsyncSelect 
            cacheOptions 
            loadOptions={inputValue => debouncedLoadOptions(inputValue)}
            placeholder="Type your restaurant name / cuisine" 
            noOptionsMessage={() => noOptionsMessage}
            onChange={onChange}
            value={restaurant}
          />
        </div>) :
      null}
    </div>
  );
}

export default RestaurantInput;