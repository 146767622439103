import React, { useState } from 'react';

import LocationInput from './LocationInput';
import RestaurantInput from './RestaurantInput';
import UserMutation from './UserMutation';

import './App.css';

function Search() {
  const [selectedLocation, setSelectedLocation] = useState('');
  const [restaurant, setRestaurant] = useState(null);

  return (
    <div className="userWrapper">
      <LocationInput
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation} 
      />
      <RestaurantInput 
        selectedLocation={selectedLocation} 
        restaurant={restaurant} 
        setRestaurant={setRestaurant} 
      />
      <UserMutation 
        restaurant={restaurant}
      />
    </div>
  );
}

export default Search;
